import React from "react"
import styled from "styled-components"

import { Banner, Section } from "../components/elements"
import { ButtonA, Col, Color, Media, Wrapper } from "../components/utilities"

import { Layout } from "../components/layouts/Layout"

import sara from "../images/sara.jpg"
import brian from "../images/brian.jpg"

const SpecialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
  text-align: center;

  ${Media.below.tablet`
    text-align: center;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  `}

  p {
    margin-bottom: 3rem;
  }
`

const OrangeBar = styled.div`
  display: inline-block;
  height: 3px;
  width: 40px;
  background-color: ${Color.orange};
  margin-bottom: 1rem;

  ${Media.below.tablet`
    display: none;
  `}
`

const AttorneyPic = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  ${Media.below.desktop`
    width: 240px;
    height: 240px;
  `}

  ${props =>
    props.picture &&
    `
    background-image: url("${props.picture}")
    `}
`

const attorneys = [
  {
    name: "Sara E. Pfost",
    title: "Lorem Ipsum",
    bio:
      "Sara E. Pfost is the founding member of Pfost Law, Ltd.  Ms. Pfost’s principal areas of practice are Estate Planning, Probate and Trust Administration, Real Estate Transactions and Business Law.",
    picture: sara,
    url: "/sara-pfost",
  },
  {
    name: "Brian D. Smith",
    title: "Lorem Ipsum",
    bio:
      "Brian D. Smith is of counsel to Pfost Law, Ltd.  Mr. Smith’s principal areas of practice are Family Law, Probate and Trust Administration, Civil Litigation, and Traffic Offenses.",
    picture: brian,
    url: "/brian-smith",
  },
]

const PracticeAreas = () => {
  return (
    <Layout title="Our Attorneys">
      <Banner title="Attorneys" />
      <Section>
        <Wrapper>
          <SpecialGrid>
            {attorneys.map(item => (
              <Col>
                <a href={item.url}>
                  <AttorneyPic picture={item.picture} />
                </a>
                <h3>{item.name}</h3>
                <OrangeBar />
                <p>{item.bio}</p>
                <ButtonA to={item.url} modifiers={["primaryOutline"]}>
                  View Attorney Page
                </ButtonA>
              </Col>
            ))}
          </SpecialGrid>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default PracticeAreas
